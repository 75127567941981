import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useRecoilValue } from "recoil";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CFormLabel,
  CFormSelect,
  CButton,
  CLink,
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CWidgetStatsB,
  CPlaceholder,
  CCardText,
  CTooltip,
} from "@coreui/react";

import CIcon from "@coreui/icons-react";
import _ from "lodash";
import JSON5 from "json5";

import { VarDictionary } from "@sentia/groot-lib";
import { selectedCustomerState, multiOrgPATState } from "../../helpers/recoil";
import {
  fetcherDevopsGetItems,
  fetcherDevopsGetRepositories,
  fetcherPipelineHistoryLatest,
  fetcherRepositoryPipelineHistory,
  fetcherRepositoryPipelineHistoryForEnv,
  fetcherRepositoryPipelineHistoryForSolution,
  fetcherBicepBuildParams,
} from "../../helpers/fetchers";
import DiffModal from "./parts/missionControl/DiffModal";
import PipelineHistory from "./parts/missionControl/PipelineHistory";
import stripJsonComments from "strip-json-comments";
import { toast } from "react-toastify";
// import semver from "semver";
import JSum from "jsum";

const ModernMissionControl = () => {
  const selectedCustomer = useRecoilValue(selectedCustomerState);
  const personalAccessTokens = useRecoilValue(multiOrgPATState);
  const navigate = useNavigate();

  const [repositoryList, setRepositoryList] = useState(new Map());
  const [selectedRepository, setSelectedRepository] = useState();
  const [common, setCommon] = useState();
  const [historyVisible, setHistoryVisible] = useState(false);

  const [solutionDiff, setSolutionDiff] = useState(null);

  const [solutionData, setSolutionData] = useState(new Map());
  const [environmentData, setEnvironmentData] = useState(new Map());
  const [pipelineHistory, setPipelineHistory] = useState([]);

  const devOpsProjects = selectedCustomer.azure?.devopsRepositories.map((project) => {
    return { organization: "AzureLZ", name: project };
  });
  const externalDevopsProjects = selectedCustomer.azure?.externalDevopsProjects || "";

  const projectList = [...devOpsProjects, ...externalDevopsProjects];

  useEffect(() => {
    if (projectList) {
      setCommon();
      setSolutionData(new Map());
      setEnvironmentData(new Map());
      setPipelineHistory([]);

      let repoList = new Map();
      const getRepos = async () => {
        const dataArray = await Promise.all(
          projectList.map((project) => {
            const pat = personalAccessTokens[project.organization.toLowerCase()];
            if (pat) {
              return fetcherDevopsGetRepositories(project.organization, project.name, pat);
            } else {
              toast.error(`PAT not configured for ${project.organization}`);
            }
          })
        );

        for (const request of dataArray) {
          request?.result?.data?.value.forEach((v) => {
            if (v.isDisabled) {
              return;
            }

            const organization = v.url.match(/https:\/\/dev.azure.com\/(?<organization>\w*)\/*/).groups.organization;
            repoList.set(v.name, {
              organization: organization,
              projectId: v.project.id,
              projectName: v.project.name,
              projectDescription: v.project.description,
              repositoryId: v.id,
              name: v.name,
            });
          });
        }

        setRepositoryList(repoList);
      };
      getRepos();
    }
  }, [selectedCustomer]); // eslint-disable-line

  useEffect(() => {
    setSolutionData(new Map());
    setEnvironmentData(new Map());
    setPipelineHistory([]);

    const repo = repositoryList.get(selectedRepository);

    if (repo) {
      const fetchCommon = async () => {
        const rawCommon = await fetcherDevopsGetItems(
          repo.organization,
          repo.projectId,
          repo.repositoryId,
          "?path=%2Fcommon.json&includeContent=true",
          personalAccessTokens[repo.organization.toLowerCase()]
        );

        setCommon(JSON.parse(rawCommon.result.data.content));
      };

      fetchCommon();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRepository]);

  const getRepoHistory = useCallback(async () => {
    const repo = repositoryList.get(selectedRepository);
    const history = await fetcherRepositoryPipelineHistory(repo.projectId, repo.repositoryId);
    setPipelineHistory(history.result?.data);
  }, [selectedRepository, repositoryList]);

  const getRepoHistoryForEnv = useCallback(
    async (environment) => {
      const repo = repositoryList.get(selectedRepository);
      const history = await fetcherRepositoryPipelineHistoryForEnv(repo.projectId, repo.repositoryId, environment);
      setPipelineHistory(history.result?.data);
    },
    [selectedRepository, repositoryList]
  );

  const getRepoHistoryForSolution = useCallback(
    async (environment, solution) => {
      const repo = repositoryList.get(selectedRepository);
      const history = await fetcherRepositoryPipelineHistoryForSolution(
        repo.projectId,
        repo.repositoryId,
        environment,
        solution
      );
      setPipelineHistory(history.result?.data);
    },
    [selectedRepository, repositoryList]
  );

  function calculateStatus(currentSolutionData, latestSolutionData) {
    let errors = new Map();

    if (_.isNil(latestSolutionData) || _.isNil(currentSolutionData)) {
      return { statusOk: null, errors: errors };
    }

    if (latestSolutionData.status !== "Succeeded") {
      errors.set("deploymentStatus", "Latest deployment did not succeed");
    }
    if (latestSolutionData.deploymentMode === "Deploy Tags") {
      errors.set("deploymentMode", "Latest deployment was a 'Deploy Tags' pipeline, check the changes");
    }
    if (!latestSolutionData.matchingVersions) {
      errors.set("version", "Latest deployment did not satisfy the common version");
    }

    if (!latestSolutionData.matchingConfig) {
      errors.set("matchingConfig", "Latest deployment did not match the committed code");
    }

    if (errors.size === 0) {
      return { statusOk: true, errors: errors };
    }
    return { statusOk: false, errors: errors };
  }

  const getData = useCallback(
    async (environment, solution, mergedConfig, cache) => {
      if (!selectedRepository) {
        return;
      }

      const repo = repositoryList.get(selectedRepository);
      const history = await fetcherPipelineHistoryLatest(repo.projectId, repo.repositoryId, environment, solution);

      const commonSolution = common.solutions[environment].find((obj) => {
        return obj.name === solution;
      });

      let currentHash = "";
      let lastDeployHash = "";

      if (_.isUndefined(mergedConfig)) {
        let latestSolutionConfig = await fetcherDevopsGetItems(
          repo.organization,
          repo.projectId,
          repo.repositoryId,
          `?path=${encodeURI(`/${environment}/${solution}/config-template.json`)}&includeContent=true`,
          personalAccessTokens[repo.organization.toLowerCase()]
        );

        const paramsFiles = [common.configParameterFiles, commonSolution?.configParameterFiles]
          .filter(function (el) {
            return el;
          })
          .join(",");

        const vars = await loadConfigParams(repo, paramsFiles, cache);
        const varDictionary = new VarDictionary(vars);

        if (!_.isUndefined(latestSolutionConfig?.result?.data?.content)) {
          mergedConfig = varDictionary.merge(stripJsonComments(latestSolutionConfig.result.data.content));
        }
      }

      const latestDeployment = history.result?.data;

      if (!_.isUndefined(latestDeployment)) {
        let lastDeployConfig;
        let newSolutionData = {};
        try {
          const lastDeployObject = JSON5.parse(latestDeployment.configContent);
          lastDeployConfig = JSON.stringify(lastDeployObject, null, 4);
          const mergedObject = JSON5.parse(mergedConfig);
          mergedConfig = JSON.stringify(mergedObject, null, 4);
          lastDeployHash = JSum.digest(lastDeployConfig, "SHA256", "hex");
          currentHash = JSum.digest(mergedConfig, "SHA256", "hex");
        } catch (error) {
          toast.error(`Failed to process ${solution} in ${environment}: ${error}`);
          return null;
        }
        newSolutionData.status = history.result.data.status;
        newSolutionData.deploymentMode = history.result.data.deploymentMode;
        newSolutionData.matchingConfig = currentHash === lastDeployHash;
        newSolutionData.runtimeSolutionVersion = history.result.data.runtimeSolutionVersion;
        newSolutionData.matchingVersions = true;
        // newSolutionData.matchingVersions = semver.satisfies(
        //   history.result.data.runtimeSolutionVersion,
        //   commonSolution.solutionBranch
        // );
        newSolutionData.commitId = history.result.data.commitId;
        newSolutionData.mergedConfig = mergedConfig;
        newSolutionData.deployedConfig = lastDeployConfig;
        newSolutionData.projectId = history.result.data.projectId;
        newSolutionData.organization = history.result.data.organization || "AzureLZ";
        newSolutionData.repositoryId = history.result.data.repositoryId;

        return newSolutionData;
      }

      return null;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [common, selectedRepository]
  );

  const getSolutionDataForEnv = useCallback(
    async (environment) => {
      let trackedSolutions = 0;
      let succeededSolutions = 0;
      let upToDateSolutions = 0;

      const varCache = new Map();

      const repo = repositoryList.get(selectedRepository);

      let mergedBicepConfigs;
      if (!environmentData.has(environment)) {
        const parameterFiles = await loadBicepParameterFiles(
          repo,
          environment,
          common.solutions[environment],
          varCache
        );
        if (!_.isEmpty(parameterFiles)) {
          mergedBicepConfigs = await fetcherBicepBuildParams(parameterFiles);
        }
      }

      await Promise.all(
        common.solutions[environment].map(async (solution) => {
          let solutionObj = solutionData.get(`${environment}/${solution.name}`);
          if (_.isUndefined(solutionObj)) {
            const path = "/" + environment + "/" + solution.name;
            const mergedBicepConfig = mergedBicepConfigs?.result?.data[path];
            solutionObj = await getData(environment, solution.name, mergedBicepConfig, varCache);
            const tempMap = new Map(solutionData.set(`${environment}/${solution.name}`, solutionObj));
            setSolutionData(tempMap);
          }

          if (!_.isNull(solutionObj)) {
            if (solutionObj.status === "Succeeded") {
              succeededSolutions++;
            }
            if (solutionObj.matchingConfig && solutionObj.matchingVersions) {
              upToDateSolutions++;
            }
            trackedSolutions++;
          }

          return;
        })
      );

      const environmentInfo = {};
      environmentInfo.upToDate = Math.round((upToDateSolutions / common.solutions[environment].length) * 100);
      environmentInfo.tracked = Math.round((trackedSolutions / common.solutions[environment].length) * 100);
      environmentInfo.succeeded = Math.round((succeededSolutions / common.solutions[environment].length) * 100);

      setEnvironmentData(new Map(environmentData.set(environment, environmentInfo)));
    },
    [selectedRepository, common, repositoryList, getData, solutionData, environmentData]
  );

  function matchRuleShort(str, rule) {
    // test whether the path matches the wildcarded paramString
    var escapeRegex = (str) => str.replace(/([.*+?^=!:${}()|[]\/])/g, "\\$1");
    return new RegExp("^" + rule.split("*").map(escapeRegex).join(".*") + "$").test(str);
  }

  const loadConfigParams = async (repo, paramString, cache = new Map()) => {
    if (paramString.length > 0) {
      const paramArray = paramString.split(",");
      const paramFileListCompiled = await Promise.all(
        paramArray.map(async (paramFile) => {
          if (!paramFile.includes("*")) {
            return [paramFile]; // no wildcards present
          }

          // extract basePath and filePath from parts var
          const parts = paramFile.match(/((?<basePath>[/\w.-]*)?\/)?(?<filePath>.*\.json)/);
          if (parts) {
            const params = `?scopePath=${encodeURI(parts.groups.basePath || "/")}&recursionLevel=oneLevel`;
            const devopsData = await fetcherDevopsGetItems(
              repo.organization,
              repo.projectId,
              repo.repositoryId,
              params,
              personalAccessTokens[repo.organization.toLowerCase()]
            );

            // get the paths of all wildcarded paramFiles
            // (only blobs and the paths that match the paramFiles)
            let path = `/${parts.groups.filePath}`;
            if (!_.isUndefined(parts.groups.basePath)) {
              path = `/${parts.groups.basePath}/${parts.groups.filePath}`;
            }

            return devopsData?.result?.data?.value
              .filter((item) => item.gitObjectType === "blob")
              .filter((item) => matchRuleShort(item.path, path))
              .map((item) => item.path);
          }
        })
      );

      // get the content of all files, no wildcards should be present
      return await Promise.all(
        paramFileListCompiled.flat().map(async (paramFile) => {
          const cachedResult = cache.get(paramFile);
          if (cachedResult) {
            return cachedResult;
          }

          const params = `?path=${encodeURI(paramFile)}&includeContent=true`;
          const request = await fetcherDevopsGetItems(
            repo.organization,
            repo.projectId,
            repo.repositoryId,
            params,
            personalAccessTokens[repo.organization.toLowerCase()]
          );

          try {
            const parsedContent = JSON5.parse(request.result.data.content);
            const parsedObject = _.isString(parsedContent) ? {} : parsedContent;
            cache.set(paramFile, parsedObject);
            return parsedObject;
          } catch (error) {
            return { error: error };
          }
        })
      );
    }
  };

  const loadBicepParameterFiles = async (repo, environment, solutions, cache = new Map()) => {
    var paramFiles = new Map();

    const solutionFiles = await Promise.all(
      solutions.map(async (solution) => {
        const solutionPath = `/${environment}/${solution.name}/config.bicepparam`;
        const cachedResult = cache.get(solutionPath);
        if (cachedResult) {
          return { [solutionPath]: btoa(cachedResult) };
        }
        const params = `?path=${encodeURI(solutionPath)}&includeContent=true`;
        const request = await fetcherDevopsGetItems(
          repo.organization,
          repo.projectId,
          repo.repositoryId,
          params,
          personalAccessTokens[repo.organization.toLowerCase()]
        );

        try {
          let content = request.result.data.content;
          content = content.replace(/using\s+'[^']+'/g, "using none");

          let match;
          const regexPath = /import\s+\*\s+as\s+\w+\s+from\s+'([^']+)'|loadJsonContent\('([^']+)'\)/g;

          while ((match = regexPath.exec(content)) !== null) {
            let result = match[1].replace(/\.\.\/\.\.*/g, "");
            result = result.replace(/\.\./g, `/${environment}`);
            paramFiles.set(result, null);
          }
          cache.set(solutionPath, content);
          return { [solutionPath]: btoa(content) };
        } catch (error) {
          // toast.error(`No .bicepparam file found!`);
          return;
        }
      })
    );

    paramFiles = [...paramFiles.keys()];
    const resolvedParamFiles = await Promise.all(
      paramFiles.map(async (paramFile) => {
        const cachedResult = cache.get(paramFile);
        if (cachedResult) {
          return { [paramFile]: btoa(cachedResult) };
        }

        const params = `?path=${encodeURI(paramFile)}&includeContent=true`;
        const request = await fetcherDevopsGetItems(
          repo.organization,
          repo.projectId,
          repo.repositoryId,
          params,
          personalAccessTokens[repo.organization.toLowerCase()]
        );

        try {
          const content = request.result.data.content;
          if (paramFile.endsWith(".json")) {
            const parsedContent = JSON5.parse(content);
            const parsedObject = _.isString(parsedContent) ? {} : parsedContent;
            cache.set(paramFile, parsedObject);
          } else {
            cache.set(paramFile, content);
          }
          return { [paramFile]: btoa(content) };
        } catch (error) {
          return { error: error };
        }
      })
    );

    return solutionFiles.concat(resolvedParamFiles).reduce((acc, obj) => ({ ...acc, ...obj }), {});
  };

  if (!selectedCustomer) {
    navigate("/customer");
  }

  return (
    <>
      <CRow className="mb-3">
        <CCol>
          <CCard>
            <CCardHeader className="contrast-title">Solution selector</CCardHeader>
            <CCardBody>
              <CRow className="form-group">
                <CCol md="4">
                  <CFormLabel>
                    <strong>Customer Repository</strong>
                  </CFormLabel>
                  <CFormSelect
                    name="csla"
                    id="csla"
                    value={selectedRepository}
                    onChange={(e) => setSelectedRepository(e.target.value)}
                  >
                    <option key="0" value="0">
                      Select Repository
                    </option>
                    {Array.from(repositoryList)
                      .sort((a, b) => a[1].name.localeCompare(b[1].name))
                      .map(([r, repo], k) => {
                        return (
                          <option key={k} value={r}>
                            {repo.name}
                          </option>
                        );
                      })}
                  </CFormSelect>
                </CCol>
              </CRow>

              <CRow>
                <CCol className="mt-2" md="12">
                  {selectedRepository && (
                    <CButton
                      variant="outline"
                      onClick={() => {
                        getRepoHistory();
                        setHistoryVisible(true);
                      }}
                    >
                      Pipeline history
                    </CButton>
                  )}
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CRow className="mb-3">
        <CCol>
          <CCard>
            <CCardHeader className="contrast-title">Environment details</CCardHeader>
            <CCardBody>
              <CAccordion>
                {common &&
                  common.solutions &&
                  Object.keys(common.solutions).map((env, k) => {
                    const environmentInfo = environmentData.get(env);
                    return (
                      <CAccordionItem itemKey={`${k}-${env}`} key={`${k}-${env}`}>
                        <CAccordionHeader onClick={() => getSolutionDataForEnv(env)}>{env}</CAccordionHeader>
                        <CAccordionBody>
                          <CRow>
                            <CCol xs={4}>
                              <CWidgetStatsB
                                className="mb-3"
                                progress={{
                                  color: environmentInfo?.upToDate === 100 ? "success" : "warning",
                                  value: environmentInfo?.upToDate,
                                }}
                                text=""
                                title="Up-to-date deployments"
                                value={`${environmentInfo?.upToDate || 0}%`}
                              />
                            </CCol>

                            <CCol xs={4}>
                              <CWidgetStatsB
                                className="mb-3"
                                progress={{
                                  color: environmentInfo?.succeeded === 100 ? "success" : "warning",
                                  value: environmentInfo?.succeeded,
                                }}
                                text=""
                                title="Successful deployments"
                                value={`${environmentInfo?.succeeded || 0}%`}
                              />
                            </CCol>

                            <CCol xs={4}>
                              <CWidgetStatsB
                                className="mb-3"
                                progress={{
                                  color: environmentInfo?.succeeded === 100 ? "success" : "warning",
                                  value: environmentInfo?.tracked,
                                }}
                                text=""
                                title="Tracked solutions"
                                value={`${environmentInfo?.tracked || 0}%`}
                              />
                            </CCol>
                          </CRow>

                          <CRow className="mb-3">
                            <CCol>
                              {selectedRepository && (
                                <CButton
                                  variant="outline"
                                  onClick={() => {
                                    getRepoHistoryForEnv(env);
                                    setHistoryVisible(true);
                                  }}
                                >
                                  Get pipeline history
                                </CButton>
                              )}
                            </CCol>
                          </CRow>

                          <CRow>
                            {common.solutions[env].map((solution, i) => {
                              const solutionObject = solutionData.get(`${env}/${solution.name}`);
                              const finalStatus = calculateStatus(solution, solutionObject);
                              return (
                                <CCol xs={2} key={i}>
                                  <CCard
                                    className={`mb-3 border-${
                                      _.isNull(finalStatus.statusOk)
                                        ? "warning"
                                        : finalStatus.statusOk
                                        ? "success"
                                        : "danger"
                                    }`}
                                  >
                                    <CCardHeader
                                      className={finalStatus.statusOk === false ? "bg-danger text-white" : ""}
                                    >
                                      {solution.name}
                                    </CCardHeader>
                                    <CCardBody>
                                      {_.isUndefined(solutionObject) ? (
                                        <CCardText>
                                          Last deployment status: <CPlaceholder xs={4} />
                                          <br />
                                          Config version (committed): <CPlaceholder xs={4} />
                                          <br />
                                          Deployed version: <CPlaceholder xs={4} />
                                          <br />
                                          Matching configs?: <CPlaceholder xs={4} />
                                          <br />
                                          CommitId: <CPlaceholder xs={4} />
                                          <br />
                                        </CCardText>
                                      ) : _.isNull(solutionObject) ? (
                                        <CCardText>
                                          Last deployment status: <span className="text-warning">Unknown</span>
                                          <br />
                                          Config version (committed): {solution?.solutionBranch}
                                          <br />
                                          Deployed version: <span className="text-warning">Unknown</span>
                                          <br />
                                          Matching configs?: <span className="text-warning">Unknown</span>
                                          <br />
                                          CommitId: <span className="text-warning">Unknown</span>
                                          <br />
                                        </CCardText>
                                      ) : (
                                        <CCardText>
                                          Last deployment status:{" "}
                                          {finalStatus.errors.get("deploymentStatus") ? (
                                            <CTooltip content={finalStatus.errors.get("deploymentStatus")}>
                                              <span className="text-danger">{solutionObject?.status}</span>
                                            </CTooltip>
                                          ) : finalStatus.errors.get("deploymentMode") ? (
                                            <CTooltip content={finalStatus.errors.get("deploymentMode")}>
                                              <span className="text-warning">{solutionObject?.status} (tags)</span>
                                            </CTooltip>
                                          ) : (
                                            <span className="text-success">{solutionObject?.status}</span>
                                          )}
                                          <br />
                                          Config version (committed): {solution?.solutionBranch}
                                          <br />
                                          Deployed version:{" "}
                                          {finalStatus.errors.get("version") ? (
                                            <CTooltip content={finalStatus.errors.get("version")}>
                                              <span className="text-danger">
                                                {solutionObject?.runtimeSolutionVersion}
                                              </span>
                                            </CTooltip>
                                          ) : (
                                            <span className="text-success">
                                              {solutionObject?.runtimeSolutionVersion}
                                            </span>
                                          )}
                                          <br />
                                          Matching configs?:{" "}
                                          {finalStatus.errors.get("matchingConfig") ? (
                                            <CTooltip content={finalStatus.errors.get("matchingConfig")}>
                                              <span className="text-danger">no</span>
                                            </CTooltip>
                                          ) : (
                                            <span className="text-success">yes</span>
                                          )}
                                          <br />
                                          CommitId:{" "}
                                          {solutionObject?.commitId && (
                                            <CLink
                                              target="_blank"
                                              className="text-decoration-underline"
                                              href={`https://dev.azure.com/${solutionObject.organization}/${solutionObject.projectId}/_git/${solutionObject.repositoryId}/commit/${solutionObject.commitId}`}
                                            >
                                              {solutionObject.commitId.substring(0, 7)}
                                            </CLink>
                                          )}
                                          <br />
                                        </CCardText>
                                      )}
                                      <CButton
                                        variant="outline"
                                        color="dark"
                                        onClick={() => getData(env, solution.name)}
                                      >
                                        <CIcon name={"cil-sync"} />
                                      </CButton>
                                      <CButton
                                        variant="outline"
                                        color="dark"
                                        className="ms-2"
                                        onClick={() => setSolutionDiff(`${env}/${solution.name}`)}
                                      >
                                        Compare
                                      </CButton>
                                      <CButton
                                        variant="outline"
                                        color="dark"
                                        className="ms-2"
                                        onClick={() => {
                                          getRepoHistoryForSolution(env, solution.name);
                                          setHistoryVisible(true);
                                        }}
                                      >
                                        Pipeline history
                                      </CButton>
                                    </CCardBody>
                                  </CCard>
                                </CCol>
                              );
                            })}
                          </CRow>
                        </CAccordionBody>
                      </CAccordionItem>
                    );
                  })}
              </CAccordion>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <PipelineHistory
        visible={historyVisible}
        onClose={() => setHistoryVisible(false)}
        pipelineData={pipelineHistory}
      />

      <DiffModal
        visible={!_.isNull(solutionDiff)}
        latestConfig={solutionData.get(solutionDiff)?.deployedConfig}
        currentConfig={solutionData.get(solutionDiff)?.mergedConfig}
        onClose={() => setSolutionDiff(null)}
      />
    </>
  );
};

export default ModernMissionControl;
