import { getConfig } from "./config";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authProvider";

export const mockMessage = { "error": "", "result": "Mock", "status": 200, "isValid": true } // eslint-disable-line

const pca = new PublicClientApplication(msalConfig);
const accounts = pca.getAllAccounts();

async function getAccessToken() {
  if (accounts.length > 0) {
    const request = {
      scopes: ["api://40f4851e-0b8d-4041-b9e2-23e7495e7073/.default"], //Switch to this one when backend is using on-behalf-of tokens
      //scopes: ["https://management.azure.com/user_impersonation"],
      account: accounts[0],
    };

    let response;
    try {
      response = await pca.acquireTokenSilent(request);
    } catch (error) {
      response = await pca.acquireTokenPopup(request);
    }

    return response.accessToken;
  }

  return null;
}

export function getCosmosObject(collection) {
  return {
    resourceGroupName: process.env.REACT_APP_AZURE_COSMOSDB_RESOURCEGROUPNAME,
    accountName: process.env.REACT_APP_AZURE_COSMOSDB_ACCOUNTNAME,
    databaseName: process.env.REACT_APP_AZURE_COSMOSDB_DATABASENAME,
    masterKey: process.env.REACT_APP_AZURE_COSMOSDB_MASTERKEY,
    collectionName: collection,
  };
}

export function getSecureCosmosObject(collection) {
  return {
    resourceGroupName: process.env.REACT_APP_AZURE_COSMOSDB_RESOURCEGROUPNAME,
    accountName: process.env.REACT_APP_AZURE_COSMOSDB_ACCOUNTNAME,
    databaseName: process.env.REACT_APP_AZURE_COSMOSDB_DATABASENAME,
    collectionName: collection,
    KVUrl: process.env.REACT_APP_AZURE_COSMOSDB_KEYVAULTURL,
    KVSecret: process.env.REACT_APP_AZURE_COSMOSDB_KEYVAULTSECRET,
    //"masterKey": process.env.REACT_APP_AZURE_COSMOSDB_MASTERKEY //backwards compatible
  };
}

export function getCloudbillingIOCosmosObject(collection, env, country) {
  return {
    resourceGroupName: process.env["REACT_APP_AZURE_IO_" + env + "_" + country + "_COSMOSDB_RESOURCEGROUPNAME"],
    accountName: process.env["REACT_APP_AZURE_IO_" + env + "_" + country + "_COSMOSDB_ACCOUNTNAME"],
    databaseName: process.env["REACT_APP_AZURE_IO_" + env + "_" + country + "_COSMOSDB_DATABASENAME"],
    masterKey: process.env["REACT_APP_AZURE_IO_" + env + "_" + country + "_COSMOSDB_MASTERKEY"],
    collectionName: collection,
  };
}

let handleError = function (err) {
  return { error: err, result: null, status: 500 };
};

export function inRange(x, min = 200, max = 399) {
  return (x - min) * (x - max) <= 0;
}

export async function fetchFunctionHelper(
  endpoint,
  method,
  headers,
  body,
  range = [200, 399],
  timeout = 180000,
  abortControllerRef
) {
  try {
    let result;
    let abortController = { guid: null, controller: new AbortController() };

    // Create an abort controller to cancel request
    if (abortControllerRef !== undefined) {
      abortController = abortControllerRef;
    }

    // Make sure we stop the call after timeout has expired
    const timeoutId = setTimeout(() => abortController.controller.abort(), timeout);

    // When using APIM for Sentia, inject auth header for user
    if (endpoint.includes(getConfig("backend"))) {
      const azureAccessToken = await getAccessToken();
      headers = { ...headers, Authorization: `Bearer ${azureAccessToken}` };
    }

    // GET skips BODY !
    if (["GET"].includes(method)) {
      result = await fetch(endpoint, {
        method: method,
        headers: headers,
        signal: abortController.controller.signal,
      }).catch(handleError);
    } else {
      result = await fetch(endpoint, {
        method: method,
        headers: headers,
        body: JSON.stringify(body),
        signal: abortController.controller.signal,
      }).catch(handleError);
    }

    // Remove Timeout
    clearTimeout(timeoutId);

    // Return a body
    if (result.ok) {
      const timestamp = Math.floor(Date.now() / 1000);

      let json = null;
      try {
        json = await result.json();
        json = { data: json, _timestamp: timestamp };
      } catch {
        body = { data: result };
      }

      // Debug message
      return {
        error: null,
        result: json ? json : body,
        status: result?.status,
        isValid: inRange(result.status, range[0], range[1]),
        headers: result?.headers,
      };
    } else {
      return { error: result.error?.message, result: null, status: result.status, isValid: false };
    }
  } catch {
    return { error: "Unknown", result: null, status: 500.1, isValid: false };
  }
}

export async function fetchDurableFunctionHelper(
  endpoint,
  method,
  headers,
  body,
  range = [200, 399],
  timeout = 60000,
  abortControllerRef
) {
  var result = await fetchFunctionHelper(endpoint, method, headers, body, range, timeout, abortControllerRef);
  try {
    if (result.status === 202 && result.headers.get("Location") !== null) {
      result = await fetchRetry(
        result.headers.get("Location") + "&showInput=false",
        "GET",
        "",
        headers,
        range,
        timeout,
        abortControllerRef
      );
    }
    return result.result;
  } catch (e) {
    return { error: e.message, result: null, status: 500, isValid: false };
  }
}

async function fetchRetry(endpoint, method, headers, body, range, timeout, abortControllerRef, pollingInterval = 4000) {
  if (!abortControllerRef.controller.signal.aborted) {
    var result = await fetchFunctionHelper(endpoint, method, headers, body, range, timeout, abortControllerRef);

    if (result.status !== 200) {
      await new Promise((r) => setTimeout(r, pollingInterval));
      result = await fetchRetry(
        endpoint,
        method,
        headers,
        body,
        range,
        timeout,
        abortControllerRef,
        (pollingInterval = 4000)
      );
    }
    return result;
  }
}
