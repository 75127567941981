import { fetchFunctionHelper, getSecureCosmosObject, fetchDurableFunctionHelper } from "../fetcher";
import { formatter } from "../formatter";

export async function fetcherApplicationPermissions() {
  const endpoint = formatter("/core/tenants/permissions");
  const method = "GET";
  const headers = {
    "Content-Type": "application/json",
  };

  return fetchFunctionHelper(endpoint, method, headers);
}

export async function fetcherTenantConfig() {
  const endpoint = formatter("/core/tenants/config");
  const method = "GET";
  const headers = {
    "Content-Type": "application/json",
  };

  return fetchFunctionHelper(endpoint, method, headers);
}

export function fetcherMicrosoftPartnerCenterList() {
  const endpoint = formatter("/core/partnercenter/customers");
  const method = "GET";
  const headers = {
    "Content-Type": "application/json",
  };

  return fetchFunctionHelper(endpoint, method, headers);
}

export function fetcherClearviewPartnerCenterList() {
  const endpoint = formatter("/core/customers");
  const method = "GET";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    cspTenantId: process.env.REACT_APP_AZURE_AD_CSP_TENANTID,
    subscriptionId: process.env.REACT_APP_AZURE_AD_CSP_IUR_SUBSCRIPTIONID,
    cosmosDb: getSecureCosmosObject("clearview"),
  };

  return fetchFunctionHelper(endpoint, method, headers, body);
}

export function fetcherClearviewServicePrincipalList() {
  const endpoint = formatter("/core/tenants/servicePrincipals");
  const method = "GET";
  const headers = {
    "Content-Type": "application/json",
  };
  return fetchFunctionHelper(endpoint, method, headers);
}

export function fetcherClearviewPartnerCenterCustomer(tenantId) {
  const endpoint = formatter(`/core/customers/${tenantId}`);
  const method = "GET";
  const headers = {
    "Content-Type": "application/json",
  };

  return fetchFunctionHelper(endpoint, method, headers);
}

export function fetcherClearviewGDAPByCustomer(tenantId) {
  const endpoint = formatter(`/core/partnercenter/customers/${tenantId}/gdap`);
  const method = "GET";
  const headers = {
    "Content-Type": "application/json",
  };

  return fetchFunctionHelper(endpoint, method, headers);
}

export function fetcherClearviewGDAPAssignments(tenantId, relationshipId) {
  const endpoint = formatter(`/core/partnercenter/customers/${tenantId}/gdap/${relationshipId}/assignments`);
  const method = "GET";
  const headers = {
    "Content-Type": "application/json",
  };

  return fetchFunctionHelper(endpoint, method, headers);
}

export function fetcherClearviewGDAPGenerateForCustomer(tenantId, displayName) {
  const endpoint = formatter(`/core/partnercenter/customers/${tenantId}/gdap`);
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };

  const body = {
    displayName: displayName,
  };

  return fetchFunctionHelper(endpoint, method, headers, body);
}

export function fetcherClearviewGenerateGDAPAssignment(tenantId, relationshipId, securityGroupId) {
  const endpoint = formatter(`/core/partnercenter/customers/${tenantId}/gdap/${relationshipId}/assignments`);
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };

  const body = {
    securityGroupId: securityGroupId,
  };

  return fetchFunctionHelper(endpoint, method, headers, body);
}
export function fetcherClearviewSaveCustomer(customerJson) {
  const endpoint = formatter(`/core/customers/${customerJson.id}`);
  const method = "PUT";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    customerInfo: customerJson,
  };
  return fetchFunctionHelper(endpoint, method, headers, body);
}

export function fetcherClearviewCreateCustomer(customer) {
  const endpoint = formatter(`/core/customers`);
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = customer;
  return fetchFunctionHelper(endpoint, method, headers, body);
}

export async function fetcherQualityCheckUpdateGreylist(subscriptionID, tenantID, greylistInputData) {
  const endpoint = formatter("/QualityCheck-UpdateGreylist");
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    subscriptionId: subscriptionID,
    tenantId: tenantID,
    greylistInput: greylistInputData,
  };

  return fetchFunctionHelper(endpoint, method, headers, body);
}

export async function fetcherQualityCheckSaveReport(qcScope, qcReport) {
  const endpoint = formatter("/QualityCheck-SaveReport");
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    qcScope: qcScope,
    qcReport: qcReport,
  };

  return fetchFunctionHelper(endpoint, method, headers, body);
}

export async function fetcherGetTenantSubscriptions(tenantID) {
  const endpoint = formatter("/Subscriptions-GetTenantSubscriptions");
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    tenantId: tenantID,
  };

  return fetchFunctionHelper(endpoint, method, headers, body);
}

export async function fetcherQualityCheckRunReport(
  subscriptionId,
  tenantId,
  scopeType,
  resourceFilter,
  abortController
) {
  const endpoint = formatter("/orchestrators/QualityCheck-RunReportOrchestrator");
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };

  const body = {
    subscriptionId: subscriptionId,
    tenantId: tenantId,
    resourceGroupFilter: scopeType === "resourceGroup" ? resourceFilter : "",
    resourceId: scopeType === "resource" ? resourceFilter : "",
  };

  var result = await fetchDurableFunctionHelper(endpoint, method, headers, body, undefined, 300000, abortController);

  return result;
}

export async function fetcherDocsProcessMarkdownOrchestratorAzDocs(
  resourceTypeId,
  customer,
  tenantId,
  documentationScope,
  abortController
) {
  const endpoint = formatter("/orchestrators/Docs-ProcessMarkdownOrchestrator");
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    docType: "azdocs",
    resourceTypeId: resourceTypeId,
    customer: customer,
    tenantId: tenantId,
    documentationScope: documentationScope,
    cosmosDb: getSecureCosmosObject("resourceDefs"),
  };

  var result = await fetchDurableFunctionHelper(endpoint, method, headers, body, undefined, 300000, abortController);

  return result;
}

export async function fetcherDocsProcessMarkdownOrchestratorIPSec(
  customer,
  description,
  vpnName,
  connectionName,
  outputMode,
  abortController
) {
  const endpoint = formatter("/orchestrators/Docs-ProcessMarkdownOrchestrator");
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    docType: "ipsec",
    customer: customer,
    description: description,
    vpnName: vpnName,
    connectionName: connectionName,
    outputMode: outputMode,
  };

  var result = await fetchDurableFunctionHelper(endpoint, method, headers, body, undefined, 300000, abortController);

  return result;
}

export async function fetcherDocsGetVPNList(tenantId) {
  const endpoint = formatter("/docs-getvpnlist");
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    tenantId: tenantId,
  };

  return fetchFunctionHelper(endpoint, method, headers, body);
}

export async function fetcherDocsGenerateSite(customerId, documentationScope) {
  const endpoint = formatter(`/core/documentation/customers/${customerId}/scopes/${documentationScope}/publish`);
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {};

  var result = await fetchFunctionHelper(endpoint, method, headers, body, [200, 399], 180000);

  return result;
}

export async function fetcherDocsSavePage(customerId, documentationScope, exportId, markdownContent) {
  const endpoint = formatter(
    `/core/documentation/customers/${customerId}/scopes/${documentationScope}/docs/${exportId}`
  );
  const method = "PUT";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = markdownContent;

  var result = await fetchFunctionHelper(endpoint, method, headers, body);

  return result;
}

export async function fetcherDocsGetOverview(customerId, documentationScope) {
  const endpoint = formatter("/Docs-GetOverview");
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    documentationScope: `${customerId}:${documentationScope}`,
  };

  var result = await fetchFunctionHelper(endpoint, method, headers, body);

  return result;
}

export async function fetcherDocsGetDocumentationScopes(customerId) {
  const endpoint = formatter(`/core/documentation/customers/${customerId}/scopes`);
  const method = "GET";
  const headers = {
    "Content-Type": "application/json",
  };

  var result = await fetchFunctionHelper(endpoint, method, headers);

  return result;
}

export async function fetcherDocsDeleteDocumentationScopes(customerId, documentationScopeId) {
  const endpoint = formatter(`/core/documentation/customers/${customerId}/scopes/${documentationScopeId}`);
  const method = "DELETE";
  const headers = {
    "Content-Type": "application/json",
  };

  var result = await fetchFunctionHelper(endpoint, method, headers);

  return result;
}

export async function fetcherDocsUpdateDocumentationScopes(customerId, documentationScope) {
  const endpoint = formatter(`/core/documentation/customers/${customerId}/scopes/${documentationScope.id}`);
  const method = "PUT";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = documentationScope;

  var result = await fetchFunctionHelper(endpoint, method, headers, body);

  return result;
}

export async function fetcherDocsCreateDocumentationScopes(customerId, documentationScope) {
  const endpoint = formatter(`/core/documentation/customers/${customerId}/scopes`);
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = documentationScope;

  var result = await fetchFunctionHelper(endpoint, method, headers, body);

  return result;
}

export async function fetcherDocsGetPage(customerId, documentationScope, exportId) {
  const endpoint = formatter(
    `/core/documentation/customers/${customerId}/scopes/${documentationScope}/docs/${exportId}`
  );
  const method = "GET";
  const headers = {
    "Content-Type": "application/json",
  };

  var result = await fetchFunctionHelper(endpoint, method, headers);

  return result;
}

export async function fetcherDocsDeletePage(customerId, exportId, documentationScope, docs) {
  const endpoint = formatter(`/core/documentation/customers/${customerId}/scopes/${exportId}/delete-docs`);
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    docs: docs,
  };

  var result = await fetchFunctionHelper(endpoint, method, headers, body);

  return result;
}

export async function fetcherFlowsGet(documentationScope) {
  const endpoint = formatter("/Flows-Get");
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    cosmosDb: getSecureCosmosObject("integrationFlows"),
    documentationScope: documentationScope,
  };

  return fetchFunctionHelper(endpoint, method, headers, body);
}

export async function fetcherFlowsSave(documentationScope, flows) {
  const endpoint = formatter("/Flows-Save");
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    cosmosDb: getSecureCosmosObject("integrationFlows"),
    documentationScope: documentationScope,
    flows: flows,
  };

  return fetchFunctionHelper(endpoint, method, headers, body);
}

export async function fetcherSendNewSecretToKeyVault(keyvaultName, secretName, kvSecretValue, tenantId, fqdnTagToKV) {
  const endpoint = formatter("/KeyVault-SaveSecret");
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    keyvaultName: keyvaultName,
    secretName: secretName,
    kvSecretValue: kvSecretValue,
    tenantId: tenantId,
    fqdnTagToKV: fqdnTagToKV,
  };
  return fetchFunctionHelper(endpoint, method, headers, body);
}

export async function fetcherBicepBuildParams(parameters) {
  const endpoint = formatter("/MissionControl-BuildParams");
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = parameters;
  return fetchFunctionHelper(endpoint, method, headers, body);
}

export async function fetcherSendToPasswordState(customerName, sentiaFqdn, secret, enigmaUser, userType, image) {
  const endpoint = formatter("/Enigma-UpdatePasswordState");
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    customerName: customerName,
    sentiaFqdn: sentiaFqdn,
    secret: secret,
    enigmaUser: enigmaUser,
    userType: userType,
    image: image,
  };
  return fetchFunctionHelper(endpoint, method, headers, body);
}

export async function fetcherGetKeyVault(subscriptionId) {
  const endpoint = formatter("/Subscription-GetKeyVault");
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    subscriptionId: subscriptionId,
  };

  return fetchFunctionHelper(endpoint, method, headers, body);
}

export async function fetcherGetKeyVaultSecrets(keyvaultName, tenantId) {
  const endpoint = formatter("/KeyVault-ListSecrets");
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    keyvaultName: keyvaultName,
    tenantId: tenantId,
  };

  return fetchFunctionHelper(endpoint, method, headers, body);
}

export async function fetcherGetSecretFromKeyVault(keyvaultSecret, tenantId) {
  const endpoint = formatter("/KeyVault-GetSecret");
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    keyvaultSecret: keyvaultSecret,
    tenantId: tenantId,
  };
  return fetchFunctionHelper(endpoint, method, headers, body);
}

export async function fetchersVPNGetVPNList(tenantId) {
  const endpoint = formatter("/VPN-GetVPNList");
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    tenantId: tenantId,
  };

  return fetchFunctionHelper(endpoint, method, headers, body);
}

export async function fetcherVPNGetVPNConfigOrchestrator(
  certificateName,
  subscriptionId,
  vpnGateway,
  vpnGatewayRG,
  abortController
) {
  const endpoint = formatter("/orchestrators/VPN-GetVPNConfigOrchestrator");
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    certificateName: certificateName,
    vaultName: process.env.REACT_APP_AZURE_AD_CSP_IUR_VPN_P2S_KEYVAULT,
    subscriptionId: subscriptionId,
    vpnGateway: vpnGateway,
    vpnGatewayRG: vpnGatewayRG,
  };

  var result = await fetchDurableFunctionHelper(endpoint, method, headers, body, undefined, 300000, abortController);

  return result;
}

export async function fetcherRunPreflightChecks(customerId, solutionName, config) {
  const endpoint = formatter(`/core/preflight/run`);
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    tenantId: customerId,
    solutionName: solutionName,
    template: config,
  };

  var result = await fetchFunctionHelper(endpoint, method, headers, body);

  return result;
}

export async function fetcherPipelineHistoryLatest(projectId, repoId, env, solutionName) {
  const endpoint = formatter(`/core/pipelines/${projectId}/${repoId}/${env}/${solutionName}/latest`);
  const method = "GET";
  const headers = {
    "Content-Type": "application/json",
  };

  return fetchFunctionHelper(endpoint, method, headers);
}

export async function fetcherPipelineHistory() {
  const endpoint = formatter(`/core/pipelines`);
  const method = "GET";
  const headers = {
    "Content-Type": "application/json",
  };

  return fetchFunctionHelper(endpoint, method, headers);
}

export async function fetcherRepositoryPipelineHistory(projectId, repoId) {
  const endpoint = formatter(`/core/pipelines/${projectId}/${repoId}`);
  const method = "GET";
  const headers = {
    "Content-Type": "application/json",
  };

  return fetchFunctionHelper(endpoint, method, headers);
}

export async function fetcherRepositoryPipelineHistoryForEnv(projectId, repoId, environment) {
  const endpoint = formatter(`/core/pipelines/${projectId}/${repoId}/${environment}`);
  const method = "GET";
  const headers = {
    "Content-Type": "application/json",
  };

  return fetchFunctionHelper(endpoint, method, headers);
}

export async function fetcherRepositoryPipelineHistoryForSolution(projectId, repoId, environment, solution) {
  const endpoint = formatter(`/core/pipelines/${projectId}/${repoId}/${environment}/${solution}`);
  const method = "GET";
  const headers = {
    "Content-Type": "application/json",
  };

  return fetchFunctionHelper(endpoint, method, headers);
}

export async function fetcherDocsCreateVersion(customerId, documentationScope, version, description) {
  const endpoint = formatter(`/core/documentation/customers/${customerId}/scopes/${documentationScope}/versions`);
  const method = "POST";
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    version: version,
    description: description,
  };

  var result = await fetchFunctionHelper(endpoint, method, headers, body);

  return result;
}

export async function fetcherDocsGetVersions(customerId, documentationScope) {
  const endpoint = formatter(`/core/documentation/customers/${customerId}/scopes/${documentationScope}/versions`);
  const method = "GET";
  const headers = {
    "Content-Type": "application/json",
  };

  var result = await fetchFunctionHelper(endpoint, method, headers);

  return result;
}
